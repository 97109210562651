import { IEvent } from "../interfaces";
import { createEvent, prettifyDate } from "../services/calendar.service";
import Navigation from "./Navigation";
import { Button, Card, CardActions, CardContent, createTheme, Grid, Link, Theme, ThemeProvider, Typography, useMediaQuery } from "@mui/material"
import EventIcon from "@mui/icons-material/Event"
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from "react-router-dom";

function getStoreLink(storeName: string) {
  switch (storeName) {
    case "Redcap's Corner":
      return "https://www.redcapscorner.com/"
    case "Philly Game Shop":
      return "https://www.phillygameshop.com/"
    default:
      return ""
  }
}

function getStoreHtml(storeName: string) {
  const storeLink = getStoreLink(storeName)
  if (storeLink != "")
    return <Link target="_blank" rel="noopener noreferrer" href={storeLink}>{storeName}<OpenInNewIcon /></Link>
  return <Typography>{storeName}</Typography>
}

function Events({ events, resolved }: { events: IEvent[], resolved: boolean }) {
  const navigate = useNavigate();
  const internalNav = (clickEvent: any, url: string) => {
    clickEvent.preventDefault()
    navigate(url)
  }

  const vertexTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: "#ffcc01",
        light: "#fff176",
        dark: "#f57f17",
        contrastText: "#000",
      },
    }
  })
  
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  let newEvents: IEvent[] = [...events]
  const today = Date.now()
  if ( today < Date.parse('1/27/2025') && resolved ) {
    newEvents.push({cube: "Vertex", date: "Sun, Jan 26 2025 5:00:00 GMT", location: "Redcap's Corner", players: 128, description: "A two-day cube event from the minds behind Steel City Cube"})
  }
  newEvents.sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
  return (
    <div>
      <Navigation />
      <Grid container spacing={2}>
      {newEvents.map((event: IEvent, index: number) => {
        const eventSubtitle = (event.owner ? " by " + event.owner : "") + (event.players ? " (" + event.players + " players)": "");
        const altSubtitle = (event.altcube && event.altowner ? " by " + event.altowner : "");
        const storeLink = event.location ? getStoreLink(event.location) : "";
        const eventDate = new Date(event.date)

        if (event.cube === 'Vertex') {
          return (
            <ThemeProvider theme={vertexTheme}>
              <Grid item lg={6} xs={12} key={index}>
                <Card variant="outlined" className="vertical vertex">
                  <CardContent>
                    <Typography color="#ffcc01"><b>{event.cube.toLocaleUpperCase()}</b>{eventSubtitle}</Typography>
                    <Typography>Sat, Jan 25 2025 - Sun, Jan 26 2025</Typography>
                    {event.location ? (getStoreHtml(event.location)) : (<></>)}
                    {event.description ? (<Typography>{event.description}</Typography>) : (<></>)}
                  </CardContent>
                  <CardActions className={isSmallScreen ? 'smallEventActions' : ''}>
                    <Button endIcon={<EventIcon />} download="event.ics" href={encodeURI('data:text/calendar;attachment;charset=utf8,' + createEvent('Vertex MtG Cube', 'A 128-player cube event from the minds behind Steel City Cube', event.location || '', 'https://www.phillymtgcube.com/vertex', event.date))}>Save to calendar</Button>
                    <Button endIcon={<InfoIcon />} href="https://www.phillymtgcube.com/vertex" onClick={(clickEvent) => internalNav(clickEvent, '/vertex')}>Details</Button>
                  </CardActions>
                </Card>
              </Grid>
            </ThemeProvider>
          )
        }

        return (
          <Grid item lg={6} xs={12} key={index}>
            <Card variant="outlined" className="vertical">
              <CardContent>
                <Typography><b>{event.cube.toLocaleUpperCase()}</b>{eventSubtitle}</Typography>
                {event.altcube ? (<Typography><i>feat. <b>{event.altcube.toLocaleUpperCase()}</b>{altSubtitle}</i></Typography>) : (<></>)}
                <Typography>{prettifyDate(eventDate)}</Typography>
                {event.location ? (getStoreHtml(event.location)) : (<></>)}
                {event.description ? (<Typography>{event.description}</Typography>) : (<></>)}
              </CardContent>
              <CardActions className={isSmallScreen ? 'smallEventActions' : ''}>
                <Button endIcon={<EventIcon />} download="event.ics" href={encodeURI('data:text/calendar;attachment;charset=utf8,' + createEvent('MtG Cube', `${event.cube} ${eventSubtitle}`, event.location || '', storeLink, event.date))}>Save to calendar</Button>
                {event.hyperlink ? (<Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href={event.hyperlink}>{`${event.cube} on CubeCobra`}</Button>) : (<></>)}
                {event.altcube && event.althyperlink ? (<Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href={event.althyperlink}>{`${event.altcube} on CubeCobra`}</Button>) : (<></>)}
              </CardActions>
            </Card>
          </Grid>
        )
      })}
      </Grid>
    </div>
  )
}

export default Events;