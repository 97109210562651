import Navigation from "./Navigation";
import { Box, Button, createTheme, Grid, Link, ThemeProvider, Typography } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { purple } from "@mui/material/colors";


function Vertex() {
  const vertexTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: "#ffcc01",
        light: "#fff176",
        dark: "#f57f17",
        contrastText: "#000",
      },
      secondary: purple,

    }
  })

  return (
    <Box>
      <Navigation />
      <ThemeProvider theme={vertexTheme}>
        <Box paddingTop="2rem" display="flex" flexDirection="column" alignItems="center">
          <Box width="50vw">
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}><Box display="flex" justifyContent="center"><img alt="vertex logo" className="vertexLogo" src="vertex.png"></img></Box></Grid>
              <Grid item lg={6} xs={12}><Box display="flex" flexDirection="column" justifyContent="center" alignItems="left">
                <Typography fontSize="5vw" fontWeight="bold" color="#ffcc01">VERTEX</Typography>
                <Typography fontWeight="bold" paddingBottom="1rem">A Two-Day, Six-Draft Cube Event from the minds behind Steel City Cube</Typography>
                <Typography fontWeight="bold">January 25-26, 2025 @ <Link target="_blank" rel="noopener noreferrer" href="https://www.redcapscorner.com/">Redcap's Corner<OpenInNewIcon /></Link></Typography>
              </Box></Grid>
            </Grid>
            <Grid container spacing={2} paddingTop="2rem">
              <Grid item lg={6} xs={12}><Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href="https://bsky.app/profile/vertexmtg.bsky.social" style={{ padding: "1rem" }} fullWidth variant="outlined" color="info">Follow on BlueSky</Button></Grid>
              <Grid item lg={6} xs={12}><Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href="https://discord.com/invite/qKBYaHcZEs" style={{ padding: "1rem" }} fullWidth variant="outlined" color="secondary">Join the Discord</Button></Grid>
              {/* <Grid item lg={6} xs={12}><Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href="https://x.com/VertexMTG" style={{ padding: "1rem" }} fullWidth variant="outlined" color="info">Follow on Twitter</Button></Grid> */}
              <Grid item xs={12}><Button endIcon={<OpenInNewIcon />} target="_blank" rel="noopener noreferrer" href="https://buy.stripe.com/fZe4j91wB5wT7pSfZ0" style={{ padding: "1rem" }} fullWidth variant="outlined">Buy Tickets - $50</Button></Grid>
              </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  )
}

export default Vertex;