import { Button, createTheme, ThemeProvider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const internalNav = (event: any, url: string) => {
    event.preventDefault()
    navigate(url)
  }

  const vertexTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: "#ffcc01",
        light: "#fff176",
        dark: "#f57f17",
        contrastText: "#000",
      },
    }
  })

  const today = Date.now()

  return (
    <div>
        <hr />
        <div className="navigation">
            <Button href="https://www.phillymtgcube.com/" variant="outlined" disabled={location.pathname === '/'} onClick={(event) => internalNav(event, '/')} className={location.pathname === '/' ? 'active' : 'inactive'}>Events</Button>
            <Button href="https://www.phillymtgcube.com/cubes" variant="outlined" disabled={location.pathname === '/cubes'} onClick={(event) => internalNav(event, '/cubes')} className={location.pathname === '/cubes' ? 'active' : 'inactive'}>Cubes</Button>
            {today < Date.parse('1/27/2025') ? <ThemeProvider theme={vertexTheme}><Button href="https://www.phillymtgcube.com/vertex" variant="contained" disabled={location.pathname === '/vertex'} onClick={(event) => internalNav(event, '/vertex')} className={location.pathname === '/vertex' ? 'active' : 'inactive'}>Vertex</Button></ThemeProvider> : <></>}
        </div>
        <hr />
    </div>
  )
}

export default Navigation;